import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import {devices} from "../../../../utils/constant";

document.addEventListener("DOMContentLoaded", e => {

    if (document.querySelectorAll('.sliders-category').length === 0 && !devices) return false

    /**
     * Init Swiper Slider
     * @type {Swiper}
     */
    new Swiper('.sliders-category', {
        modules: [Navigation],
        slidesPerView: 8,
        navigation: {
            nextEl: ".swiper-category-sliders-wide-next",
            prevEl: ".swiper-category-sliders-wide-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 3
            },
            480: {
                slidesPerView: 4
            },
            760: {
                slidesPerView: 5
            },
            1024: {
                slidesPerView: 6
            },
            1100: {
                slidesPerView: 8
            }
        }
    })

})
