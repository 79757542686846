import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

document.addEventListener("DOMContentLoaded", e => {

    if (!document.getElementById('slider-wide')) return false

    new Swiper('.sliders-wide', {
        modules: [Navigation, Autoplay],
        loop: true,
        slidesPerView: 4,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".swiper-slide-sliders-wide-next",
            prevEl: ".swiper-slide-sliders-wide-prev",
        },
        breakpoints: {
            200: {
                slidesPerView: 1
            },
            920: {
                slidesPerView: 2
            },
            1500: {
                slidesPerView: 3
            },
            1700: {
                slidesPerView: 4
            }
        }
    })

})
