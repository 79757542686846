import {getFavoriteStatus} from "../../../../utils/favorite/favorite";

document.addEventListener("DOMContentLoaded", e => {

    const preporukSliders = document.getElementById('preporuk-sliders')

    if (!preporukSliders) return false

    preporukSliders.addEventListener('click', e => {
        if (e.target.closest('.item-grid-favorite')) {
            e.preventDefault()
            getFavoriteStatus(e.target)
        }
    })

})
