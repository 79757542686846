import {initSwiperSlider} from "../../../../utils/init-swiper-slider";
import {templateProduct} from "../../products/components/template-announcement"
import ScrollEvents from "scroll-events/dist/scroll-events";
import {getProductsEndlessLoading} from "../../../../services/products-service";
import Cookie from "js-cookie";

/**
 * Additional records loading
 * status variable
 * @type {boolean}
 */
let status = true

const noResult = document.getElementById('no-result'),
    preload = document.getElementById('preload')

const body = document.querySelector('body'),
    preporukSliders = document.getElementById('preporuk-sliders')

/**
 * Calculates how many pixels are
 * left before reloading, and then calls
 */
const scrollEvents = new ScrollEvents()
new ScrollEvents(body)

scrollEvents.on('scroll:progress', function (event) {
    const searchControlGeo = document.getElementById('search-control-geo')

    let pageY = scrollEvents.y,
        height = body.offsetHeight

    /**
     *
     */
    pageY = pageY + 1800;

    if (pageY >= height && status && noResult.classList.contains('hide')) {
        /**
         * Changes the status making it clear
         * that the download is happening and
         * waiting for a response from the server
         * so that the request does not occur again
         * @type {boolean}
         */
        status = false

        /**
         * Counts how many records are
         * currently displayed in the block
         * @type {number}
         */
        const cols = preporukSliders.querySelectorAll('.col-6').length

        /**
         * Displays the loading block
         * @type {string}
         */
        preload.style.display = 'flex'

        /**
         * Sends a request to get records
         */
        getProductsEndlessLoading({
            skip: cols,
            take: 24,
            countryId: Cookie.get('hitno_country')
        }).then(e => insertHtmlTemplateAds(e.data.data))
    }
})

/**
 *
 * @param data
 * @returns {boolean}
 */
const insertHtmlTemplateAds = async (data) => {
    let dom = ``

    /**
     * Change the status of the variable
     * letting you know that everything
     * has loaded
     * @type {boolean}
     */
    status = true

    /**
     * Hide the preloader after
     * loading
     * @type {string}
     */
    document.getElementById('preload').style.display = 'none'

    /**
     * Displays a block that there are
     * no more records if no records have arrived
     */
    if (data.length === 0) {
        document.getElementById('no-result').classList.remove('hide')
    }

    /**
     * Starts the ad loading cycle
     */
    for (let i = 0; data.length > i; i++) {
        dom += templateProduct(data[i], 'col-lg-3 col-md-3 col-sm-6 col-6')
    }

    /**
     *
     */
    document.getElementById('preporuk-sliders').insertAdjacentHTML('beforeend', dom)

    /**
     * Triggers after 500ms the
     * activation of the slider on ads
     */
    initSwiperSlider()
}
