import {i18Translate} from "../../../../utils/i18-init"

/**
 * Creates a basic ad display template
 * @param el
 * @param row
 * @returns {}
 */
export const templateProduct = (el, row) => {
    let gallery = '',
        ads = ``

    /**
     * Run a loop to generate a read
     * block for the gallery
     */
    for (let index = 0; el['gallery'].length > index; index++) {
        if (!el['gallery'][index]['count']) {
            gallery += `<div class="swiper-slide"><img class="image-autofocus ad-gallery-hover-slider-image" src="${el['gallery'][index]['image']}"></div>`
        } else {
            gallery += `<div class="swiper-slide"><img class="image-autofocus ad-gallery-hover-slider-image" src="${el['gallery'][index]['image']}"><span class="item-grid-count-photo"><i class="las la-camera"></i><div>${el['gallery'][index]['count']}</div></span></div>`
        }
    }

    /**
     * Create a variable with a
     * post content block
     */
    const body = `
            <a href="${el['url']}" class="item-grid" title="${el['title']}" data-id="${el['id']}">
                <div class="item-grid-img">
                    <div title="${el['title']}">
                        <div class="swiper swiper-previews">
                            <div class="swiper-wrapper">${gallery}</div>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
                <div class="item-grid-info">
                    <div class="item-grid-favorite" disabled="false" data-status="${el['favorite']}">
                        <i class="lar la-heart"></i>
                    </div>
                    <div class="item-grid-price">
                        <span class="item-grid-price-now">${el['price']}</span>
                    </div>
                    <div class="item-grid-title">${el['title']}</div>
                    <div class="item-grid-description">${el['description']}</div>
                </div>
            </a>
    `

    /**
     * We put the variable in a
     * wrapper if necessary
     */
    if (row) ads = `<div class="${row}">${body}</div>`

    /**
     * We check whether the wrapper is
     * needed at all, if not, remove it
     */
    if (!row) ads = body

    return ads
}

export const templateLineProduct = (el, row) => {
    let gallery = '',
        ads = ``

    let width = window.innerWidth

    let video = ``,
        view = ``,
        phone = ``

    let document = ``,
        user = (el['user_name']) ? `<span class="item-grid-info-user-name">${el['user_name']}</span>` : `<span class="item-grid-info-user-name">ID ${el['user_id']}</span>`

    /**
     *
     */
    if (el['video']) video = `<span class="video"><i class="las la-play"></i></span>`
    if (el['online_view']) view = `<div class="item-grid-online"><span class="item-grid-online-view">${i18Translate('build-online-view.viewing-online')}</span></div>`
    if (el['document']) document = `<div class="item-grid-info-user-verified-item"><i class="las la-award"></i><span>${i18Translate('template.the-documents-checked')}</span></div>`

    /**
     * Run a loop to generate a read
     * block for the gallery
     */
    for (let index = 0; el['gallery'].length > index; index++) {
        if (!el['gallery'][index]['count']) {
            gallery += `<div class="swiper-slide"><img class="image-autofocus ad-gallery-hover-slider-image" src="${el['gallery'][index]['image']}" alt="${el['title']}"></div>`
        } else {
            gallery += `<div class="swiper-slide"><img class="image-autofocus ad-gallery-hover-slider-image" src="${el['gallery'][index]['image']}"><span class="item-grid-count-photo"><i class="las la-camera"></i><div>${el['gallery'][index]['count']}</div></span></div>`
        }
    }

    let toolsDesktop = `
        <div class="item-grid-favorite"  data-status="${el['favorite']}">
            <i class="lar la-heart"></i>
            <div class="item-grid-help">${i18Translate('template.favourites')}</div>
        </div>
        <div class="item-grid-share">
            <i class="las la-share"></i>
            <div class="item-grid-help">${i18Translate('template.share')}</div>
        </div>
    `

    let toolsMobile = `
        <div class="item-grid-chat">
            <i class="las la-comment-alt"></i>
        </div>
        <div class="item-grid-favorite"  data-status="${el['favorite']}">
            <i class="lar la-heart"></i>
        </div>
    `

    let userMeta = `
        <div class="item-grid-info-user">
            <div class="item-grid-info-user-group">
                <span class="item-grid-info-user-status">${el['status']}</span>
                ${user}
                <div class="item-grid-info-user-verified">
                                    ${document}
                                    <div class="item-grid-info-user-verified-item"><span>${i18Translate('template.on-hitno-with')} ${el['created_user']}</span></div>
                                </div>
                            </div>
                            <div class="btn-group">
                                ${phone}
                                <div class="btn-custom item-grid-chat" data-recipient="${el['user_id']}">${i18Translate('template.write-to-the-seller')}</div>
                            </div>
        </div>
    `

    let description = `<div class="item-grid-description">${el['description']}</div>`

    if (width < 576) {
        toolsDesktop = ''
        userMeta = ''
        description = `<div class="item-grid-description">${el['description'].substr(0, 30)}...</div>`
    } else {
        toolsMobile = ''
    }

    const tools = `
        <div class="item-grid-tool">
            ${toolsMobile}
            ${toolsDesktop}
        </div>
    `

    /**
     * Create a variable with a
     * post content block
     */
    const body = `
            <a
                href="${el['url']}"
                class="item-grid"
                title="${el['title']}"
                data-id="${el['id']}"
                data-price="${el['price']}"
                data-image="${(el['gallery'].length) ? el['gallery'][0]['image'] : ''}"
                itemprop="url"
            >
                <div class="item-grid-img">
                    <div class="swiper swiper-previews" title="${el['title']}">
                        <div class="swiper-wrapper">${gallery}</div>
                        <div class="swiper-pagination"></div>
                    </div>
                    ${video}
                    <div class="item-grid-data">
                        <span class="item-grid-info-date">${el['date']}</span>
                    </div>
                </div>
                <div class="item-grid-info">

                     ${tools}

                    <div class="item-grid-info-meta">
                        <div class="item-grid-info-detail">
                            <div class="item-grid-title" itemprop="name">${el['title']}</div>
                            ${view}
                            <div class="item-grid-price" itemscope itemprop="offers" itemtype="http://schema.org/Offer">
                                <span class="item-grid-price-now" itemprop="price">${el['price']}</span>
                            </div>
                            ${description}
                        </div>

                        ${userMeta}

                    </div>
                </div>
            </a>
    `

    /**
     * We put the variable in a
     * wrapper if necessary
     */
    if (row) ads = `<div class="${row}">${body}</div>`

    /**
     * We check whether the wrapper is
     * needed at all, if not, remove it
     */
    if (!row) ads = body

    return ads
}






































